/* Reset default margin and padding */
body, h1, h2, h3, p, ul, li {
  margin: 0;
  padding: 0;
}

/* Set a global font family and font size */
body {
  font-family: Arial, sans-serif;
  font-size: 16px;
}

/* Style the hero section */
.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #f1f1f1;
}

.hero-content {
  flex: 1;
  max-width: 600px;
}

.hero-content h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.hero-content p {
  margin-bottom: 20px;
}

.hero-image img {
  width: 100%;
  max-width: 400px;
}

/* Style the mango varieties section */
.mango-varieties-section {
  padding: 40px;
}

.mango-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.mango-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.mango-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.mango-card p {
  margin-bottom: 10px;
}

/* Style the order section */
.order-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #f1f1f1;
}

.order-content {
  flex: 1;
  max-width: 600px;
}

.order-content h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.order-image img {
  width: 100%;
  max-width: 400px;
}

/* Style the additional information section */
.additional-info-section {
  padding: 40px;
  background-color: #f9f9f9;
}

.additional-info-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.additional-info-content ul {
  list-style-type: disc;
  margin-left: 20px;
}

.additional-info-content li {
  margin-bottom: 10px;
}

/* Style the testimonial section */
.testimonial-section {
  padding: 40px;
}

.testimonial-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.testimonial-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.testimonial-card p {
  margin-bottom: 10px;
}

.testimonial-card .customer-name {
  font-style: italic;
}

/* Style the contact section */
.contact-section {
  padding: 40px;
  text-align: center;
}

.contact-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-section p {
  margin-bottom: 10px;
}

/* Style the footer section */
.footer-section {
  padding: 20px;
  text-align: center;
  background-color: #f1f1f1;
}
.appointments_banner {
  background-image: linear-gradient(
      89.78deg,
      rgba(27, 60, 66, 0.8) 0.14%,
      rgba(58, 58, 58, 0.05) 99.76%
    ),
    url(../../images/InsuranceandForms/banner.png);
  background-repeat: no-repeat;

  background-size: cover;
  width: 100%;
  height: 480px;
}
@media only screen and (max-width: 768px) {
  .appointments_banner{
    background-image: linear-gradient(89.78deg, rgba(27, 60, 66, 0.8) 0.14%, rgba(58, 58, 58, 0.05) 99.76%), url(../../images/InsuranceandForms/mobile.png);
    background-repeat: no-repeat;

 
  width: 100%;

}
  
  }

.square {
  background-image: url(../../images/InsuranceandForms/Shape.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 70%;
  height: 350px;
}
.forms_font {
  font-family: Georgia;
  font-size: 64px;
  font-weight: 700;
  line-height: 96px;
  letter-spacing: 0em;
  text-align: left;
}

.forms_font1 {
  font-family: Georgia;
  font-size: 24px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .forms_font {
    font-family: Georgia;
    font-size: 22px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.025em;
    text-align: left;
  }
  .forms_font1 {
    font-family: Georgia;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-align: left;
  }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
  .forms_font {
    font-family: Georgia;
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0.025em;
    text-align: left;
  }
  .forms_font1 {
    font-family: Georgia;
    font-size: 26px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-align: left;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 992px) {
  .forms_font {
    font-family: Georgia;
    font-size: 54px;
    font-weight: 700;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
  }

  .forms_font1 {
    font-family: Georgia;
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.forms_font_li {
  font-family: Georgia;
  font-size: 22px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}
.forms_font_li1 {
  font-family: Georgia;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.forms_font_1 {
  font-family: Georgia;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.forms_font_0 {
  font-family: Georgia;
  font-size: 42px;
  font-weight: 700;
  line-height: 63px;
  letter-spacing: 0em;
  text-align: left;
}
.shadow {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.forms_font_0_mobile {
  font-family: Georgia;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .forms_font_1_mobile {
    font-family: Georgia;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }
  .forms_font_li_mobile {
    font-family: Georgia;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .hero-image {
    width: 100%;
    max-width: 800px;
    height: 400px;
    overflow: hidden;
  }
  
  .hero-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .home-content {
    margin-top: 50px;
    text-align: center;
  }
  
  .home-content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .home-content p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 30px;
    max-width: 600px;
  }
  
  .order-now-button {
    padding: 10px 30px;
    border: none;
    border-radius: 5px;
    background-color: #F9A826;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .order-now-button:hover {
    background-color: #FFC107;
  }
  
.contact_banner {
  background-image: linear-gradient(
      89.78deg,
      rgba(27, 60, 66, 0.8) 0.14%,
      rgba(58, 58, 58, 0.05) 99.76%
    ),
    url(../../images/Contact/1.png);
  background-repeat: no-repeat;

  background-size: cover;
  width: 100%;
  height: 480px;
}
.contact_square {
  background-image: url(../../images/Contact/Shape.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 350px;
  width: 70%;
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
  .forms_font {
    font-family: Georgia;
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0.025em;
    text-align: left;
  }
  .forms_font1 {
    font-family: Georgia;
    font-size: 26px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-align: left;
  }
}

.contact_font1 {
  font-family: Georgia;
  font-size: 42px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}

.contact_font2 {
  font-family: Georgia;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.font2 {
  font-family: Georgia;
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
}

.font3 {
  font-family: Georgia;
  font-size: 22px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
}
@media only screen and (max-width: 768px) {
  .contact_font1 {
    font-family: Georgia;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
  }

  .contact_font2 {
    font-family: Georgia;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .font2 {
    font-family: Georgia;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .font3 {
    font-family: Georgia;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
  .contact_font1 {
    font-family: Georgia;
    font-size: 36px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
  }
}

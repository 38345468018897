.patient_banner{
    background-image: linear-gradient(89.78deg, rgba(27, 60, 66, 0.8) 0.14%, rgba(58, 58, 58, 0.05) 99.76%), url(../../images/Patient/12.png);
    height: 100vh;
    width: 100%;
}
.patient_btn_bg{
    background-color: #B19A73 ;
}
.white_height{
    height: 600px;
    width: 1000px;
}
.mango-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    font-family: Arial, sans-serif;
  }
  
  .mango-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
  
  .uls{
    list-style: none;
    text-decoration: none;
  }
  .mango-header h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .mango-header img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .mango-content {
    max-width: 800px;
    text-align: center;
  }
  
  .mango-content h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .mango-content ul {
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }
  ul{
    list-style: none;
  }
  .mango-content li {
    font-size: 20px;
    margin-bottom: 10px;
    text-decoration: none;
  }
  
  .mango-content p {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;

  }
 
  
/* Reset default margin and padding */
body, h1, h2, h3, p, ul, li {
  margin: 0;
  padding: 0;
}

/* Set a global font family and font size */
body {
  font-family: Arial, sans-serif;
  font-size: 16px;
}

/* Set the page container styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
}

/* Set the heading styles */
h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

/* Set the services grid layout */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

/* Set the service card styles */
.service-card {
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.service-card:hover {
  background-color: #e0e0e0;
}

.service-card h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.service-card p {
  margin-bottom: 10px;
}

.service-card a {
  display: inline-block;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.service-card a:hover {
  background-color: #45a049;
}
.order-form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
    .payment-info {
        margin-top: 10px;
        font-size: 14px;
        color: gray;
      }
      .payment-info1 {
        margin-top: 10px;
        font-size: 14px;
        color: rgb(58, 57, 57);
      }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input,
  select,
  textarea {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    font-family: inherit;
  }
  
  input:focus,
  select,
  textarea:focus {
    outline: none;
    border-color: #6c63ff;
  }
  
  .submit-order-btn {
    background-color: #f3c623;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .submit-order-btn:hover {
    background-color: #d5a400;
  }
  
  .error {
    display: block;
    margin-top: 5px;
    color: #ff0000;
    font-size: 14px;
  }
  
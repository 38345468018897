.HeaderCss {
  font-family: Georgia;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  max-height: 80px;
  background-color: white;
  z-index: 1000;


  
  /* padding-left: 150px;
  padding-right: 150px; */
  
}

@media screen and (max-width: 768px) {
  .HeaderCss{
max-height: 120px !important;
  }
}
#list{
 text-decoration: none; 
 color: #6a6a6a;
}
.vrcolor{
  color: #6a6a6a;
}
.logoCss {
  width: 115.78px;
  height: 98.61px;
}
.customCss {
  font-family: Georgia;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: white; 
  background-color: #46564c;
  border-radius: 8px;
  border: 2px solid #46564c;
}
.customCss:hover {
  font-family: Georgia;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #46564c; 
  background-color: #F6F6F6;
  border-radius: 8px;
  border: 2px solid #46564c;
}

/* .customCss1 :hover{
  background-color:antiquewhite ;
  color: #46564c;
  border: none;
} */
#list:hover {
  color: #b19a73;
  /* text-decoration: underline;
  tex
  text-underline-offset: 52px */
  padding-bottom: 45px;
  border-bottom: 6px solid #b19a73;
}

@media screen and (max-width: 1000px) {
  #list:hover {
    color: #b19a73  !important;
    /* text-decoration: underline;
    tex
    text-underline-offset: 52px */
    padding-bottom: 2px !important;
    border-bottom: 2px solid #b19a73;
  }
}


@media screen and (max-width: 375px) {
 .dhairya{
    font-size: smaller;
    /* text-decoration: underline;
    tex
    text-underline-offset: 52px */
   
  }
}


#list:focus {
  color: #b19a73;
  font-weight: 500;
  
}

.iconWidth{
width: 26px;
}